function VCardGenerator() {
  const generateVCard = (contactInfo) => {
    const makeVCardVersion = () => `VERSION:3.0`;
    const makeVCardInfo = (info) => `N:${info}`;
    const makeVCardName = (name) => `FN:${name}`;
    const makeVCardOrg = (org) => `ORG:${org}`;
    const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${phone}`;
    const makeVCardAdr = (address) => `ADR;TYPE=WORK,PREF:;;${address}`;
    const makeVCardEmail = (email) => `EMAIL:${email}`;
    const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;
    const makeVCardSocialMedia = (socialMedia) => `URL;TYPE=linkedin:${socialMedia.linkedin}\nURL;TYPE=twitter:${socialMedia.twitter}\nURL;TYPE=instagram:${socialMedia.instagram}\nURL;TYPE=facebook:${socialMedia.facebook}`;
    const makeVCardProfileImage = (profileImage) => `PHOTO;VALUE=URL;TYPE=PNG:${profileImage}`;

    const vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardInfo(contactInfo.fullName)}
${makeVCardName(contactInfo.fullName)}
${makeVCardOrg(contactInfo.organization)}
${makeVCardTel(contactInfo.phone)}
${makeVCardAdr(contactInfo.address)}
${makeVCardEmail(contactInfo.email)}
${makeVCardSocialMedia(contactInfo.socialMedia)}
${makeVCardProfileImage(contactInfo.profileImage)}
${makeVCardTimeStamp()}
END:VCARD`;

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'contact.vcf';
    link.click();

    URL.revokeObjectURL(url);
  };

  return {
    generateVCard,
  };
}

export default VCardGenerator;
