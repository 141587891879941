import React, { useEffect, useState } from "react";
import "../styles/profile-creation.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SERVER_URL from "../config/serverURL";
import protectedRoute from "./Protected";
import Loading from "./Loading";
import { toast } from "react-toastify";
import UploadAvatar from "./UploadAvatar";
import base64ToFile from "../config/base64ToFile";
function ProfileCreation() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    username: "",
    bio: "",
    email: "",
    phone: "",
  });

  const [image, setImage] = useState(null);
  const [serverImage, setServerImage] = useState("");
  const [serverImageName, setServerImageName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [handleError, setHandleError] = useState({
    name: false,
    username: false,
    bio: false,
    email: false,
    phone: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [crop, setCrop] = useState(false);
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);
  const [colorActive,setColorActive]=useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/sign-in");
      return;
    }

    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute();
        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          try {
            const response = await axios.get(SERVER_URL + "/api/getUser", {
              params: { userID: localStorage.getItem("token") },
            });

            if (response.status === 200) {
              const fetchedData = response.data;

           
              setUserDetails((prevUserDetails) => ({
                ...prevUserDetails,
                name: fetchedData.name,
                username: fetchedData.username,
                bio: fetchedData.bio,
                email: fetchedData.email
                  ? fetchedData.email
                  : fetchedData.googleEmail
                  ? fetchedData.googleEmail
                  : "",
                phone: fetchedData.phoneNumber
                  ? fetchedData.phoneNumber.toString()
                  : fetchedData.mobileNo
                  ? fetchedData.mobileNo.toString()
                  : "",
              }));
              if(fetchedData.googleEmail){
                setColorActive("email");
              }else{
                setColorActive("phone");
              }
              if (fetchedData.profileImage) {
                setServerImage(
                  `${SERVER_URL}//userImage/${fetchedData.profileImage.filename}`
                );
                setServerImageName(
                  `${SERVER_URL}//userImage/${fetchedData.profileImage.filename}`
                );
              } else {
                setServerImage("../../public/images/profile-creation.svg");
              }
              setIsLoading(false);
            } else {
              console.log("Error");
              setIsLoading(false);
            }
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkProtected();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));

    setHandleError((prevHandleError) => ({
      ...prevHandleError,
      [name]: false, // Reset the specific error state when input changes
    }));
  };
  const handleUsernameChange = (e) => {
    let { value } = e.target;
  
    // Convert to lowercase and remove spaces
    value = value.toLowerCase().replace(/\s/g, '');
  
    // Update the state with the modified username
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      username: value,
    }));
  
    // Reset the specific error state when input changes
    setHandleError((prevHandleError) => ({
      ...prevHandleError,
      username: false,
    }));
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    setServerImage(URL.createObjectURL(file));
    setSrc(URL.createObjectURL(file));
    setPreview(URL.createObjectURL(file));
    setCrop(true);
  };

  const handleSaveProfile = async () => {
    const newErrors = { ...handleError };
    const data = { ...userDetails };

    if (!userDetails.name) {
      newErrors.name = true;
    }

    if (!userDetails.username) {
      newErrors.username = true;
    }

    if (!userDetails.bio) {
      newErrors.bio = true;
    }
    if (!userDetails.phone) {
      newErrors.phone = true;
    }

    if (!userDetails.email) {
      newErrors.email = true;
    }

    setUserDetails(data);
    setHandleError(newErrors);
    //remove space and make username lowercase
    // Check if any errors exist
    let isURL = serverImage
      ? serverImage.startsWith("http") || serverImage.startsWith("https")
      : true;

    if (!Object.values(newErrors).some((error) => error)) {
      const formData = new FormData();
      if (!isURL) {
        if (!image) {
          try {
            const file = base64ToFile(serverImage, "image");
            if (file && file.size > 10 * 1024 * 1024){
              toast.error("Image size should be less than 10MB");
              return;
            }
            formData.append("image", file);
          } catch (error) {
            isURL = true;
          }
        } else {
          if (image && image.size > 10 * 1024 * 1024){
            toast.error("Image size should be less than 10MB");
            return;
          }
          formData.append("image", image);
        }
      }

      formData.append("userID", localStorage.getItem("token"));
      axios
        .post(SERVER_URL + "/api/editUser", {
          userDetails, // Use the modified 'data' here
          userID: localStorage.getItem("token"),
        })
        .then((response) => {
          if (response.status === 200) {
            if (!isURL) {

              axios
                .post(SERVER_URL + "/api/editProfileImage", formData)
                .then((imageResponse) => {
                  if (imageResponse.status === 200) {
                    console.log("Image Uploaded");
                  } else {
                    console.log("Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (localStorage.getItem("username") === "") {
              toast.success("Profile Created Successfully");
            } else {
              toast.success("Profile Updated Successfully");
            }
            localStorage.setItem("username", userDetails.username); // You might want to use 'data.username' here
            navigate("/");
          } else {
            console.log("Error");
          }
        })
        .catch(() => {
          setErrorMessage("Username already exists");
        });
    }
  };
  function handleCropChange() {
    setCrop(false);
    if (!preview) return;
    setServerImage(preview);
    setImage(null);
  }
  function handleCropCancel() {
    setCrop(false);
    setSrc(null);
    setPreview(null);
    setServerImage(serverImageName);
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="profile-creation">
      <div className="profile-creation-container">
        <div className="back-button" onClick={() => navigate("/")}>
          <img src="./images/arrow.png" alt="back-button" />
        </div>
        <div className="profile-creation-header">
          <label
            htmlFor="file-input"
            className="file-upload"
            style={{ backgroundImage: `url(${serverImage})` }}
          >
            <img src="./images/camera-icon.svg" alt="camera" />
          </label>
          <input type="file" id="file-input" onChange={handleFileInputChange} />
        </div>
        <div className="profile-creation-body">
          <div className="profile-creation-content">
            {errorMessage !== "" && (
              <span className="error">{errorMessage}</span>
            )}
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={userDetails.name}
              onChange={handleInputChange}
              maxLength={20}
            />
            {handleError.name && (
              <span className="error">Please Enter Your Name</span>
            )}
          </div>
          <div className="profile-creation-content">
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={userDetails.username}
              onChange={handleUsernameChange}
              maxLength={20}
            />
            {handleError.username && (
              <span className="error">Please Enter Your Username</span>
            )}
          </div>
          <div className="profile-creation-content">
            <textarea
              rows="5"
              placeholder="Bio"
              name="bio"
              value={userDetails.bio}
              onChange={handleInputChange}
              maxLength={200}
            ></textarea>
            {handleError.bio && (
              <span className="error">Please Enter Your Bio</span>
            )}
          </div>

        <div className={`phone-input profile ${colorActive === "phone" ? "active":""}`}>
            <PhoneInput
              value={userDetails.phone}
              placeholder="Phone Number"
              name="phone"
              onChange={(newPhoneValue) => {
                setUserDetails((prevUserDetails) => ({
                  ...prevUserDetails,
                  phone: newPhoneValue,
                }));

                // Reset the phone error state only if userDetails.phone is not an empty string
                if (userDetails.phone !== "") {
                  setHandleError((prevHandleError) => ({
                    ...prevHandleError,
                    phone: false, // Set phone error to false when input changes and userDetails.phone is not empty
                  }));
                }
              }}
            />

            {handleError.phone && (
              <span className="error">Please Enter Your Phone Number</span>
            )}
          </div>
          <div className={`profile-creation-content ${colorActive === "email" ? "active":""}`} >
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
            />
            {handleError.email && (
              <span className="error">Please Enter Your Email</span>
            )}
          </div>
          <div className="save-button">
            <button onClick={handleSaveProfile}>Save Profile</button>
          </div>
        </div>
        {crop && (
          <div className="crop-container">
            <UploadAvatar
              preview={preview}
              setPreview={setPreview}
              src={src}
              setSrc={setSrc}
            />
            <div className="crop-buttons">
              <button className="crop-done" onClick={handleCropChange}>
                Done
              </button>
              <button className="crop-cancel" onClick={handleCropCancel}>
                cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileCreation;
