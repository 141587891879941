import React from "react";

function LinkContainer({ togglePopup}) {
  return (
    <>
      <div className="container-link">
        <h2>Contact Info</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/phone.svg" alt="phone" />
              <h4>Phone</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("phone")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/email.svg" alt="email" />
              <h4>Email</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("email")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/whatsapp.svg" alt="whatsapp" />
              <h4>Whatsapp</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("whatsapp")}>Add</button>
            </div>
          </div>
        </div>
        <h2>Social Media</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/instagram.svg" alt="instagram" />
              <h4>Instagram</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("instagram")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/facebook.svg" alt="facebook" />
              <h4>Facebook</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("facebook")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/linkedin.svg" alt="linkedin" />
              <h4>Linkedin</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("linkedin")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/X.svg" alt="X" />
              <h4>X</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("X")}>Add</button>
            </div>
          </div>
        </div>
        <h2>More</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/website.svg" alt="website" />
              <h4>Website</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("website")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/behance.svg" alt="behance" />
              <h4>Behance</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("behance")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/medium.svg" alt="medium" />
              <h4>Medium</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("medium")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/GoogleMap.svg" alt="GoogleMap" />
              <h4>Google Map</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("GoogleMap")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/PDF.svg" alt="PDF" />
              <h4>PDF</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("PDF")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/GoogleReview.svg" alt="GoogleReview" />
              <h4>Google Review</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("GoogleReview")}>Add</button>
            </div>
          </div>
        </div>
        <h2>Music</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/spotify.svg" alt="spotify" />
              <h4>Spotify</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("spotify")}>Add</button>
            </div>
          </div>
        </div>
        <h2>Video</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/youtube.svg" alt="youtube" />
              <h4>Youtube</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("youtube")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/vimeo.svg" alt="vimeo" />
              <h4>Vimeo</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("vimeo")}>Add</button>
            </div>
          </div>
        </div>
        <h2>Payment</h2>
        <div className="link-store-items">
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/GooglePay.svg" alt="GooglePay" />
              <h4>Google Pay</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("GooglePay")}>Add</button>
            </div>
          </div>
          <div className="item-card">
            <div className="card-left">
              <img src="./social-icons/PhonePe.svg" alt="PhonePe" />
              <h4>PhonePe</h4>
            </div>
            <div className="card-right">
              <button onClick={() => togglePopup("PhonePe")}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LinkContainer;
