import Avatar from "react-avatar-edit";
const UploadAvatar = ({ preview, setPreview, src }) => {
  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (preview) => {
    setPreview(preview);
  };
  return (
    <>
      {/* {preview && <img src={preview} alt="Preview" />} */}
      <Avatar
        exportAsSquare
        exportSize={500}
        width={300}
        height={300}
        onCrop={onCrop}
        onClose={onClose}
        src={src}
      />
    </>
  );
};

export default UploadAvatar;
