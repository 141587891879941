import React, { useEffect, useState } from "react";
import TopNavbar from "./TopNavbar";
import "../styles/share.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import protectedRoute from "./Protected";
import Loading from "./Loading";
import SERVER_URL from "../config/serverURL";
import SharePopup from "./SharePopup";
import { CopyToClipboard } from "react-copy-to-clipboard";
function Share() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState(0)
  const [ShareButton, setShareButton] = useState(false);
 
  const shareUrl = `https://app.tapeasy.me/user/${username}`;
  const shareTitle = "Check out this link!";


  useEffect(() => {
    const checkProtected = async () => {
      if(!localStorage.getItem("token")){
        navigate("/sign-in");
      }
      try {
        const isProtected = await protectedRoute(); // Call the protectedRoute function directly

        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
   
          const response = await axios.get(
            SERVER_URL+`/api/qrcode?userID=${localStorage.getItem("token")}`
          );
          setIsLoading(false);
          if (response.status === 200) {
            if (response.data.username === "") {
              navigate("/profile-creation");
            }

            setUsername(response.data.username);
         
            setImage(response.data.qrcode);
            const res = await axios.get(SERVER_URL + "/api/getUser", {
              params: { userID: localStorage.getItem("token") },
            });
           
            
            setEmail(res.data.email);
            setPhone(res.data.phoneNumber);
          } else {
            setIsLoading(false);
           navigate("/sign-in");
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        navigate("/sign-in");
      }
    };

    checkProtected();
  }, [navigate]);

  const handleCopyClick = () => {
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 1500); // Reset success message after 1.5 seconds
  };

  if (isLoading) {
    return <>

    <Loading />
   
    </>;
  }
  return (
    <>
      <TopNavbar active={"share"}/>
      <div className="share">
        <div className="share-container">
          <div className="share-code">
            <div
              className="qr-code"
              style={{ backgroundImage: image && `url(${image})` }}
            ></div>
            <div className="share-code-content">
              <h4>Tap to save QR code to phones</h4>
              <div className="share-copy-link">
                <p>{`https://app.tapeasy.me/${username}`}</p>
                <CopyToClipboard text={shareUrl} onCopy={handleCopyClick}>
                {copySuccess ?  <img src="./images/double-tick.png" alt="copy" /> :  <img src="./images/copy.svg" alt="copy" /> }
                
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>

        <div className="share-cards">
          <div
            className="share-card-content"
            onClick={() =>setShareButton((prev) => (!prev))}
          >
            <img src="./images/download.svg" alt="download" />
            <h4 >Share Profile Link</h4>
          </div>
          <div className="share-card-content"
            onClick={() =>{window.open("mailto:"+email)}}>
            <img src="./images/email.svg" alt="download" />
            <h4>Share Profile Link Via Email</h4>
          </div>
          <div className="share-card-content"  onClick={() =>{window.open("tel:"+phone)}}>
            <img src="./images/chat.svg" alt="download" />
            <h4>Share Profile Link Via Message</h4>
          </div>
        </div>
        {ShareButton && (
        <SharePopup
          url={shareUrl}
          title={shareTitle}
          setShareButton={setShareButton}
      
        />
      )}
      </div>
      
    </>
  );
}

export default Share;
