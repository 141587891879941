import React, { useEffect, useState } from "react";
import "../styles/settings.css";
import TopNavbar from "./TopNavbar";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import protectedRoute from "./Protected";
import SERVER_URL from "../config/serverURL";
import Loading from "./Loading";

Modal.setAppElement("#root");
function Settings() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    name: "",
    username: "",
    bio: "",
    mobileNo: "",
    email: "",
  });
  const [phone, setPhone] = useState(0);
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/sign-in");
    }
    const checkProtected = async () => {
      try {
        const isProtected = await protectedRoute(); // Call the protectedRoute function directly

        if (!isProtected) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          const response = await axios.get(SERVER_URL + "/api/getUser", {
            params: { userID: localStorage.getItem("token") },
          });
          setIsLoading(false);
          if (response.status === 200) {
            if (response.data.username === "") {
              navigate("/profile-creation");
            }
            setUserDetails(response.data);
            setImage(response.data.profileImage.filename);
            setEmail(response.data.email);
            setPhone(response.data.phoneNumber);
          } else {
            navigate("/sign-in");
          }
        }
      } catch (error) {
        navigate("/sign-in");
      }
    };

    checkProtected();
  }, [navigate]);

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/sign-in");
  };

  const handleDelete = () => {
    axios
      .get(
        SERVER_URL + "/api/deleteUser?userID=" + localStorage.getItem("token")
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    closeModal();
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <TopNavbar active={"settings"} />
      <div className="settings">
        <div className="settings-container">
          <div className="my-account">
            <h4>My Account</h4>
            <div className="my-account-container">
              {image ? (
                <img src={`${SERVER_URL}//userImage/${image}`} alt="" />
              ) : (
                <img src="./images/user-icon.png" alt="" />
              )}
              <h4>{userDetails.name}</h4>
            </div>
          </div>

          <div className="settings-items">
            {phone !== "" && (
              <div className="settings-card">
                <h4>Profile Link</h4>
                <p>{phone}</p>
              </div>
            )}

            {email !== "" && (
              <div className="settings-card email">
                <h4>Account Email</h4>
                <p>{email}</p>
              </div>
            )}

            <a href="https://tapeasy.me/privacy-policy/" target="__blank">
              <div className="settings-card">
                <h4>Privacy Policy</h4>
                <p></p>
                <p></p>
              </div>
            </a>
            <a href="https://tapeasy.me/terms-of-use/" target="__blank">
              <div className="settings-card">
                <h4>Terms & Conditions</h4>

                <p></p>
                <p></p>
              </div>
            </a>
            <a href="https://tapeasy.me/refund-policy/" target="__blank">
              <div className="settings-card">
                <h4>Returns and Refund Policy</h4>
                <p></p>
                <p></p>
              </div>
            </a>
            <a href="https://tapeasy.me/refund-policy/" target="__blank">
              <div className="settings-card">
                <h4>Shipping Policy</h4>
                <p></p>
                <p></p>
              </div>
            </a>
            <a href="https://tapeasy.me/contact/" target="__blank">
              <div className="settings-card">
                <h4>Contact us</h4>
                <p></p>
                <p></p>
              </div>
            </a>
            <div className="settings-card" onClick={openDeleteModal}>
              <h4>Delete Account</h4>
              <p></p>
              <p></p>
            </div>
            <div className="settings-card sign-out" onClick={handleSignOut}>
              <h4>Sign Out</h4>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Account Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Account"
      >
        <h2>Confirm Account Deletion</h2>
        <p>Are you sure you want to delete your account?</p>
        <div className="modal-buttons">
          <button className="noselect" onClick={handleDelete}>
            <span className="text">Delete</span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
          <button className="noselect cancel-btn" onClick={closeModal}>
            <span className="text">Cancel</span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Settings;
