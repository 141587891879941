import React, { useState, useEffect, useRef } from "react";
import "../styles/signIn.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth, provider } from "../config/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/verifyOTP.css";
import protectedRoute from "./Protected";
import SERVER_URL from "../config/serverURL";
import { toast } from "react-toastify";
function SignIn() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otpCode, setOtpCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [resendTimer, setResendTimer] = useState(120); // 2 minutes in seconds
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const checkProtected = async () => {
        try {
          const isProtected = await protectedRoute();

          if (isProtected) {
            navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
      };
      checkProtected();
    }
  }, [navigate]);

  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    if (showOTP && resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (showOTP && resendTimer === 0) {
      // Timer expired, reset everything
      setResendTimer(120);
      setShowOTP(false);
      setConfirmationResult(null);
    }
  }, [showOTP, resendTimer]);

  const handleGoogleSubmit = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const loginResponse = await axios.post(SERVER_URL + "/api/googleLogin", {
        user: user,
        token: idToken,
      });

      if (loginResponse.status === 200) {
        localStorage.setItem("token", loginResponse.data.userID);

        navigate("/");
      }
    } catch (error) {
      console.log(error);
      // Handle the error here
      toast.error("Something went wrong");
    }
  };

  const handleInput = (index, event) => {
    const { value } = event.target;
    // Allow only one character per input field
    if (value.length > 1) {
      return;
    }
    const newOtpCode = [...otpCode];
    newOtpCode[index] = value;
    setOtpCode(newOtpCode);

    // Move focus to the next input field (if available)
    if (value.length > 0 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  function generateCaptcha() {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  }

  function sendOTP() {
    const formatPh = "+" + phoneNumber;
    const appVerifier = window.recaptchaVerifier;
    toast.success("Sending OTP", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        toast.success("OTP sent to your phone number!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Automatically close after 3 seconds
        });
        setShowOTP(true);
      })
      .catch((error) => {
        toast.error("Failed to send OTP. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Automatically close after 3 seconds
        });
      });
  }

  async function handleOTPVerification() {
    const code = otpCode.join("");

    try {
      const result = await confirmationResult.confirm(code);
      // User signed in successfully.
      const user = await result.user;

      const loginResponse = await axios.post(SERVER_URL + "/api/phoneLogin", {
        user: user,
        token: await user.getIdToken(),
      });

      if (loginResponse.status === 200) {
        localStorage.setItem("token", loginResponse.data.userID);
        toast.success("Login Successful");
        navigate("/");
      }
    } catch (error) {
      toast.error("Invalid OTP");
    }
  }

  async function resendOTP() {
    if (!showOTP) {
      return;
    }

    try {
      const formatPh = "+" + phoneNumber;
      const appVerifier = window.recaptchaVerifier;
      const newConfirmationResult = await signInWithPhoneNumber(
        auth,
        formatPh,
        appVerifier
      );
      setConfirmationResult(newConfirmationResult);
      setResendTimer(120); // Reset timer
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="sign-in">
      <div className="sign-in-container">
        <div id="recaptcha-container"></div>
        {!showOTP ? (
          <>
            <h2>Sign In</h2>
            <p>Please Enter your Phone Number</p>
            <div className="phone-input">
              <PhoneInput
                country={"in"} // Default country code, change as needed
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="Phone Number"
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="back-button sign"
              onClick={() => setShowOTP((prev) => !prev)}
            >
              <img src="./images/arrow.png" alt="back-button" />
            </div>
            <div className="logo-image">
              <img src="./images/logo.png" alt="logo" />
            </div>
            <h2 className="verify-h2">Verify 6-Digit Code</h2>
            <p>
              Please enter the 4 digit code sent to
              <br /> +{phoneNumber}
            </p>
            <div className="otp-input">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  type="number"
                  maxLength="1"
                  inputMode="numeric"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleInput(index, e)}
                  value={otpCode[index] || ""}
                  autoFocus={index === 0} // Apply autoFocus on the first input field
                />
              ))}
            </div>
            <div className="sign-in-button">
              <button onClick={handleOTPVerification}>Verify OTP</button>
            </div>
            <div className="otp-verify-footer">
              {resendTimer > 0 ? (
                <h5>
                  Resend Code {Math.floor(resendTimer / 60)}:
                  {resendTimer % 60 < 10 ? "0" : ""}
                  {resendTimer % 60}
                </h5>
              ) : (
                <h5 onClick={resendOTP}>Resend Code</h5>
              )}
            </div>
          </>
        )}

        {!showOTP && (
          <>
            <div className="sign-in-button">
              <button onClick={sendOTP}>Send OTP</button>
            </div>
            <div className="separator">
              <div className="line"></div>
              <div className="or">or</div>
              <div className="line"></div>
            </div>
            <div className="sign-in-buttons">
              <div className="sign-in-with-google" onClick={handleGoogleSubmit}>
                <img src="./images/google.png" alt="" />
              </div>
            </div>
            <div className="sign-in-footer">
              <p>By tapping Continues, you agree to our</p>
              <div className="sign-in-footer-content">
                <a href="https://tapeasy.me/terms-of-use/" target="__blank">
                  <h6>Terms & Conditions,</h6>
                </a>
                <a href="https://tapeasy.me/privacy-policy/" target="__blank">
                  <h6> Privacy Policy</h6>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SignIn;
