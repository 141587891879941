import React, { useState } from "react";
import "./admin-all-users.css";
import axios from "axios";
import SERVER_URL from "../../../config/serverURL";
import Modal from "react-modal";
Modal.setAppElement("#root");
function AdminAllUsers({
  allUsers,
  setUserData,
  setLogo,
  setCover,
  setProfileImage,
  setProfile,
  setSocialMediaLinks,
  setAllUsers,
  setAlreadyExist,
  setIsActiveChanges,
}) {
  const [search, setSearch] = React.useState("");
  const [filteredUser, setFilteredUser] = React.useState([]); // Initialize with all users
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(""); // Initialize with empty string
  const handleEdit = (id) => {
    const user = allUsers.find((user) => user._id === id);
    setUserData(user);
    setLogo(user.logo);
    setCover(user.cover);
    setProfileImage(user.profileImage);
    setProfile(true);
    setAlreadyExist(true);
    setSocialMediaLinks(user.socialMediaLinks);
  };
  function checkHandle(e, id) {
    const user = allUsers.find((user) => user._id === id);
    user.isActive = e.target.checked;
    console.log(user.isActive);
    axios
      .post(
        SERVER_URL + "/admin/api/activation",
        { userID: user._id, status: user.isActive },
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAllUsers(
            allUsers.map((user) => {
              if (user._id === id) {
                user.isActive = !user.isActive;
              }
              return user;
            })
          );
          setFilteredUser(
            filteredUser.map((user) => {
              if (user._id === id) {
                user.isActive = !user.isActive;
              }
              return user;
            })
          );
          setIsActiveChanges((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleDelete = () => {
    const userId = userIdToDelete; // Get the userId to delete
    axios
      .post(
        SERVER_URL + "/admin/api/deleteUser",
        { userID: userId },
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAllUsers(allUsers.filter((user) => user._id !== userId));
          setFilteredUser(filteredUser.filter((user) => user._id !== userId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    closeModal();
  };
  

  const openDeleteModal = (userId) => {
    setIsDeleteModalOpen(true);
    setUserIdToDelete(userId); // Assuming you have a state variable to store the userId to be deleted
  };
  

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleSearch = () => {
    if (search !== "") {
      axios
        .get(SERVER_URL + "/admin/api/user/" + search, {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        })
        .then((res) => {
          if (res.status === 200) {
            setFilteredUser(res.data);
            setAlreadyExist((prev) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const delayedSearch = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      handleSearch();
    }, 500); // 500 ms timeout to wait for user to stop typing

    setTimeoutId(newTimeoutId);
  };
  return (
    <div className="table-container">
      <div className="mails-search">
        <label htmlFor="search">
          <img
            src="./images/search.png"
            alt="search"
            onClick={handleSearch} // Direct search onClick removed
          />
        </label>
        <input
          type="text"
          placeholder="Search"
          id="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            delayedSearch(); // Call the delayed search function
          }}
        />
      </div>
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-1">Name</div>
          <div className="col col-2">Username</div>
          <div className="col col-2">Phone</div>
          <div className="col col-3">Email</div>
          <div className="col col-2">Company Name</div>
          <div className="col col-2">Date</div>
          <div className="col col-2">Edit</div>
        </li>
        {search !== "" ? (
          <>
            {filteredUser.map((user) => (
              <li className="table-row" key={user._id}>
                <div className="col col-1" data-label="Name">
                  {user.name}
                </div>
                <div className="col col-2" data-label="Username">
                  {user.username}
                </div>
                <div className="col col-2" data-label="Phone">
                  {user.phoneNumber}
                </div>
                <div className="col col-3" data-label="Email">
                  {user.email}
                </div>
                <div className="col col-2" data-label="Company Name">
                  {user.companyName}
                </div>
                <div className="col col-2" data-label="Date">
                  {user.Date.substring(0, 10)}
                </div>
                <div className="col col-2 edit" data-label="Edit">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={user.isActive}
                      onChange={(e) => checkHandle(e, user._id)}
                    />
                    <span className="slider"></span>
                  </label>
                  <i
                    className="fas fa-edit"
                    onClick={() => handleEdit(user._id)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    onClick={() => openDeleteModal(user._id)}
                  ></i>
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            {allUsers.map((user) => (
              <li className="table-row" key={user._id}>
                <div className="col col-1" data-label="Name">
                  {user.name}
                </div>
                <div className="col col-2" data-label="Username">
                  {user.username}
                </div>
                <div className="col col-2" data-label="Phone">
                  {user.phoneNumber}
                </div>
                <div className="col col-3" data-label="Email">
                  {user.email}
                </div>
                <div className="col col-2" data-label="Company Name">
                  {user.companyName}
                </div>
                <div className="col col-2" data-label="Date">
                  {user.Date.substring(0, 10)}
                </div>
                <div className="col col-2 edit" data-label="Edit">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={user.isActive}
                      onChange={(e) => checkHandle(e, user._id)}
                    />
                    <span className="slider"></span>
                  </label>
                  <i
                    className="fas fa-edit"
                    onClick={() => handleEdit(user._id)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    onClick={() => openDeleteModal(user._id)}
                  ></i>
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
      {/* Delete Account Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Account"
      >
        <h2>Confirm Account Deletion</h2>
        <p>Are you sure you want to delete your account?</p>
        <div className="modal-buttons">
          <button className="noselect" onClick={handleDelete}>
            <span className="text">Delete</span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
          <button className="noselect cancel-btn" onClick={closeModal}>
            <span className="text">Cancel</span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
              </svg>
            </span>
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AdminAllUsers;
