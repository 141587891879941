import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SERVER_URL from "../config/serverURL";
import "../styles/preview.css";
import Loading from "./Loading";
import VCardGenerator from "./VCardGenerator";
import { toast } from "react-toastify";

function ProfilePreview() {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true); // Add loading state

  const [popupForm, setPopupForm] = useState(false);
  const [userDetails, setUserDetails] = useState({
    username: "",
    name: "",
    bio: "",
    mobileNo: "",
    companyName: "",
    designation: "",
    website: "",
    email: "",
    profileImage: "",
    coverImage: "",
    logo: "",
  });
  // const socialMediaLinks = [
  //   { name: "website", url: "https://" },
  //   { name: "email", url: "mailto:" },
  //   {
  //     name: "instagram",
  //     url: "https://www.instagram.com/",
  //   },
  //   {
  //     name: "linkedin",
  //     url: "https://www.linkedin.com/in/ ",
  //   },
  //   {
  //     name: "facebook",
  //     url: "https://www.facebook.com/",
  //   },
  //   { name: "behance", url: "https://www.behance.net/" },
  //   { name: "medium", url: "https://medium.com/@" },
  //   {
  //     name: "spotify",
  //     url: "https://open.spotify.com/user/",
  //   },
  //   { name: "youtube", url: "https://www.youtube.com/@" },
  //   { name: "vimeo", url: "https://vimeo.com/" },
  //   { name: "whatsapp", url: "https://wa.me/" },
  //   { name: "phone", url: "tel:+" },
  // ];
  const [filteredLinks, setFilteredLinks] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    email: "",
    designation: "",
    company: "",
    note: "",
  });
  const [contactInfo, setContactInfo] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (userId) {
      setLoading(true);

      axios
        .get(SERVER_URL + `/api/share/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.isActive !== true) {
              toast.info("We Will Active This Profile Soon");
              navigate("/");
            }
            setUserDetails(response.data);
            //filter the links were status is true
            //if response.data.socialMediaLinks contains linkName as whatsapp on first filter

            let whatsapp = response.data?.socialMediaLinks.find(
              (item) => item.name === "whatsapp"
          );
          let instagram = response.data?.socialMediaLinks.find(
              (item) => item.name === "instagram"
          );
          let linkedin = response.data?.socialMediaLinks.find(
              (item) => item.name === "linkedin"
          );
          let facebook = response.data?.socialMediaLinks.find(
              (item) => item.name === "facebook"
          );
          let X = response.data?.socialMediaLinks.find(
              (item) => item.name === "X"
          );
          
          let socialMedia = {
              whatsapp: whatsapp ? whatsapp.link : "",
              instagram: instagram ? instagram.link : "",
              linkedin: linkedin ? linkedin.link : "",
              facebook: facebook ? facebook.link : "",
              twitter: X ? X.link : "",
          };
          
          console.log(socialMedia);
          
            setContactInfo({
              fullName: response.data.name || "",
              organization: response.data.companyName || "",
              email: response.data.email || "",
              phone: response.data.phoneNumber || "",
              address: response.data.address || "",
              socialMedia:socialMedia,
              profileImage: response.data.profileImage
                ? `${SERVER_URL}//userImage/${response.data.profileImage}`
                : "",
            });

            const filteredLinkStatus = response.data.socialMediaLinks.filter(
              (item) => item.status === true
            );
            setFilteredLinks(filteredLinkStatus);
          } else {
            console.log("Error fetching user details");
            navigate("/");
          }
        })
        .catch((error) => {
          navigate("/");
          console.log("Error fetching user details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, userId]);

  function handleSocialClick(linkName, link, linkID) {
    axios.get(
      SERVER_URL + `/api/clickLink?username=${userId}&linkID=${linkID}`
    );

    if (linkName === "email") {
      if (!link.startsWith("mailto:")) {
        link = `mailto:${link}`;
      }
      window.open(link, "_blank");
    } else if (linkName === "whatsapp") {
      if (!link.startsWith("https://wa.me/")) {
        link = `https://wa.me/${link}`;
      }
      window.open(link, "_blank");
    } else if (linkName === "phone") {
      if (!link.startsWith("tel:")) {
        link = `tel:${link}`;
      }
      window.open(link, "_blank");
    } else if (linkName === "GooglePay" || linkName === "PhonePe") {
      //copy to clipboard
      navigator.clipboard
        .writeText(link)
        .then(() => {
          toast.success("UPI ID Copied to Clipboard");
        })
        .catch((error) => {
          console.error("Error copying link:", error);
        });
    } else {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = `http://${link}`;
      }
      window.open(link, "_blank");
    }
  }
  function handleFormDataChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  function handleFormSubmit(e) {
    e.preventDefault();
    axios
      .post(SERVER_URL + `/api/addMail`, { formData, username: userId })
      .then((response) => {
        if (response.status === 200) {
          setPopupForm(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const vCardGenerator = VCardGenerator();

  const handleDownload = () => {
    vCardGenerator.generateVCard(contactInfo);
  };
  const handleBackClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      //navigate to a url https://tapeasy.me
      window.location.href = "https://tapeasy.me";
    }
  };
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="preview">
        <div className="preview-container">
          <div
            className="preview-header"
            style={{
              backgroundImage: userDetails.coverImage
                ? `url(${SERVER_URL}/userCoverImage/${userDetails.coverImage})`
                : "grey",
            }}
          >
            <div className="back-button" onClick={handleBackClick}>
              <img src="./images/arrow.svg" alt="back-button" />
            </div>
          </div>
          <div className="preview-profile">
            {userDetails.profileImage ? (
              <img
                src={`${SERVER_URL}/userImage/${userDetails.profileImage}`}
                alt="profile"
                className="profile-img"
              />
            ) : (
              <img
                src={`./images/profile-creation.svg`}
                alt="profile"
                className="profile-img"
              />
            )}
            {userDetails.logo ? (
              <img
                src={`${SERVER_URL}/logo/${userDetails.logo}`}
                alt="profile"
                className="logo-img"
              />
            ) : (
              <img
                src="./images/circle.png"
                alt="profile"
                className="logo-img"
              />
            )}

            <div className="preview-name">
              <h1>{userDetails.name}</h1>
              <img src="./images/bluetick.svg" alt="" />
            </div>
            <div className="preview-role">
              <h3>
                {userDetails.designation} <span>@</span>
              </h3>
            </div>
            <div className="preview-company-name">
              <h4>{userDetails.companyName}</h4>
              <div className="preview-company-name-bio">
                <p>{userDetails.bio}</p>
              </div>
            </div>
            <div className="preview-contact-me">
              <button>
                <p onClick={() => setPopupForm(true)}>Contact With Me</p>
              </button>
              <img
                onClick={handleDownload}
                src="./images/contact-me.svg"
                alt=""
              />
            </div>
          </div>

          <div className="preview-social">
            <div className="preview-social-links">
              {filteredLinks.map((link,index) => {
                return (
                  <div
                    className="social-icon"
                    key={index}
                    onClick={() =>
                      handleSocialClick(link.name, link.link, link._id)
                    }
                  >
                    <img src={`./social-icons/${link.name}.svg`} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
          {popupForm && (
            <>
              <div className="contact-form-container">
                <div className="contact-logo">
                  <img src="./images/logo.svg" alt="logo" />
                </div>
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Contact Me</h2>
                    <img
                      src="./images/x.svg"
                      alt=""
                      onClick={() => setPopupForm(false)}
                    />
                  </div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={handleFormDataChange}
                    value={formData.name}
                  />
                  <input
                    type="number"
                    name="mobileNo"
                    placeholder="Mobile"
                    onChange={handleFormDataChange}
                    value={formData.mobileNo}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleFormDataChange}
                    value={formData.email}
                  />
                  <input
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    onChange={handleFormDataChange}
                    value={formData.designation}
                  />
                  <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    onChange={handleFormDataChange}
                    value={formData.company}
                  />
                  <input
                    type="text"
                    name="note"
                    placeholder="Note"
                    onChange={handleFormDataChange}
                    value={formData.note}
                  />
                  <button className="connect-button" onClick={handleFormSubmit}>
                    Connect
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        {/* <a href="https://tapeasy.me" target="__blank">
          <div className="preview-bottom">
            <p>Powered By</p>
            <img src="./images/Logo-white.png" alt="" />
          </div>
        </a> */}
      </div>
    </>
  );
}

export default ProfilePreview;
